import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const Dashboard = Loadable(lazy(() => import('../views/dashboards/Dashboard')));
const Users = Loadable(lazy(() => import('../views/user/Users')));

const StoresListing = Loadable(lazy(() => import('../views/stores/StoreListing')));
const StoresCategories = Loadable(lazy(() => import('../views/stores/StoreCategories')));
const StoresQRCode = Loadable(lazy(() => import('../views/stores/StoreQRCode')));
const StoresAddQrCode = Loadable(lazy(() => import('../views/stores/CreateQRCode')));

const StoresCategoryUpdate = Loadable(lazy(() => import('../views/stores/StoreCategoryUpdate')));

const EarnPoints = Loadable(lazy(() => import('../views/earn-points/EarnPoints')));
const SpecialOffers = Loadable(lazy(() => import('../views/special-offers/SpecialOffers')));
const CreateOffer = Loadable(lazy(() => import('../views/special-offers/CreateOffer')));

const Reports = Loadable(lazy(() => import('../views/reports/Reports')));
const QRCodeScanReport = Loadable(lazy(() => import('../components/reports/QRCodeScanReport')));

const QRCodeScanByUsersReport = Loadable(lazy(() => import('../components/reports/QRCodeScanByUsers')));

const WaysToEarnReport = Loadable(lazy(() => import('../components/reports/WaysToEarnReport')));
const VouchersReport = Loadable(lazy(() => import('../components/reports/VoucherReport')));
const VoucherUsersReport = Loadable(lazy(() => import('../components/reports/VoucherUserReport')));
const ReferAFriendReport = Loadable(lazy(() => import('../components/reports/ReferFriendReport')));

const Settings = Loadable(lazy(() => import('../views/settings/Settings')));
const PushNotification = Loadable(lazy(() => import('../views/push-notifications/PushNotification')));
const ActivityLog = Loadable(lazy(() => import('../views/activity-log/ActivityLog')));
const Vouchers = Loadable(lazy(() => import('../views/vouchers/Vouchers')));
const AddVoucher = Loadable(lazy(() => import('../views/vouchers/AddVoucher')));
const UpdateVoucher = Loadable(lazy(() => import('../views/vouchers/UpdateVoucher')));

const Banners = Loadable(lazy(() => import('../views/banners/Banner')));
const AddBanner = Loadable(lazy(() => import('../views/banners/AddBanner')));

const AddPushNotification = Loadable(lazy(() => import('../views/push-notifications/CreatePushNotification')));

/***** Auth Pages ****/
const Login = Loadable(lazy(() => import('../views/auth/Login')));

const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));
/*const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
*/

/*****Routes******/

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/earn-points" /> },
      /*{ path: '/dashboard', name: 'Dashboard', exact: true, element: <Dashboard /> },*/
      { path: '/users', name: 'Users', exact: true, element: <Users /> },
      
      { path: '/stores/store-listing', name: 'Store Listing', exact: true, element: <StoresListing /> },
      { path: '/stores/categories', name: 'Categories', exact: true, element: <StoresCategories /> },
      { path: '/stores/qr-code', name: 'QR Code', exact: true, element: <StoresQRCode /> },
      { path: '/stores/add-qr-code', name: 'QR Code', exact: true, element: <StoresAddQrCode /> },
      { path: '/stores/update-qr-code/:id', name: 'QR Code', exact: true, element: <StoresAddQrCode /> },
      { path: '/stores/update-category/:id', name: 'Categories', exact: true, element: <StoresCategoryUpdate /> },

      { path: '/special-offers', name: 'Special Offers', exact: true, element: <SpecialOffers /> },
      { path: '/create-offer', name: 'Special Offers', exact: true, element: <CreateOffer /> },
      { path: '/update-offer/:id', name: 'Special Offers', exact: true, element: <CreateOffer /> },

      { path: '/earn-points', name: 'Earn Points', exact: true, element: <EarnPoints /> },

      { path: '/vouchers', name: 'Vouchers', exact: true, element: <Vouchers /> },
      { path: '/vouchers/voucher', name: 'Vouchers', exact: true, element: <AddVoucher /> },
      { path: '/update-voucher/:id', name: 'Vouchers', exact: true, element: <UpdateVoucher /> },

      { path: '/banners/banner', name: 'Banners', exact: true, element: <Banners /> },
      { path: '/add-banner', name: 'Banners', exact: true, element: <AddBanner /> },
      { path: '/add-banner/:id', name: 'Banners', exact: true, element: <AddBanner /> },

      { path: '/add-notification', name: 'Push Notifications', exact: true, element: <AddPushNotification /> },

      { path: '/reports', name: 'Reports', exact: true, element: <Reports /> },
      { path: '/reports/vouchers', name: 'Reports', exact: true, element: <VouchersReport /> },
      { path: '/reports/qr-code-scanned', name: 'Reports', exact: true, element: <QRCodeScanReport /> },
      { path: '/reports/qr-code-scanned-user/:qr_code_id', name: 'Reports', exact: true, element: <QRCodeScanByUsersReport /> },
      { path: '/reports/ways-to-earn', name: 'Reports', exact: true, element: <WaysToEarnReport /> },
      { path: '/reports/refer-a-friend', name: 'Reports', exact: true, element: <ReferAFriendReport /> },
      { path: '/reports/voucher-users/:voucher_id', name: 'Reports', exact: true, element: <VoucherUsersReport /> },

      { path: '/settings', name: 'Settings', exact: true, element: <Settings /> },
      { path: '/push-notification', name: 'Push Notification', exact: true, element: <PushNotification /> },
      { path: '/activity-log', name: 'Activity Log', exact: true, element: <ActivityLog /> },
      
      { path: '/earn-points', name: 'Earn Points', exact: true, element: <EarnPoints /> },      
    ],
  },

  {
      path: '/',
      element: <BlankLayout />,
      children: [
          { path: 'login', element: <Login /> },
          { path: 'reset-password', element: <RecoverPassword /> },
          { path: '404', element: <Error /> },
          { path: '*', element: <Navigate to="/auth/404" /> },
      ],
  }
];

export default ThemeRoutes;
