export default {
    //baseurl: 'https://staging-apps.precision.com.au/staging/api/v4/admin/',
    baseurl: 'https://apps-admin.precision.com.au/production/api/v4/admin/',
    adminurl: 'http://localhost:3000/',

    /*** Login ***/
    login: 'user/login',

    /*** ResetPassword ***/
    resetPassword: 'https://apps-admin.precision.com.au/production/api/v4/user/password/update',
    //resetPassword: 'https://staging-apps.precision.com.au/staging/api/v4/user/password/update',

    updateUserTag: 'centre/:center_id/user/tag',
    
    /*** Header ***/
    centers:'centres',

    /*** Offers ***/
    getOffersEvents: ':center_id/offers-events',
    getOffers: ':center_id/offers-events/?filter_by[type]=offer',
    getCenterOffers: ':center_id/offers-events?filter_by[type]=offer',
    addOffer: 'offer-event',
    updateOffer: 'offer-event/:id',
    offerShowHide: ':center_id/offer-event/:id/hide-show',
    getOfferInfo: 'offer-event/:id',
    deleteOffer: ':center_id/offer-event/:id',

    duplicateOffer: ':center_id/offer-event/duplicate',

    // Participating Retailers
    getParticipatingRetailers: 'centre/:center_id/participant-retailers',
    addParticipatingRetailers: 'participant-retailers',

    /*** Store categories ***/    
    getCategories:'centre/:center_id/categories',
    getCategoryById:'centre/:center_id/category/:id',
    updateCategory:'category',

    /*** REPORTS ***/
    getVoucherClaimedReport: 'report/centre/:center_id/vouchers',
    getQRCodeScannedReport: 'report/centre/:center_id/qr-codes',
    getVoucherClaimedByUsers: 'report/centre/:center_id/voucher/:voucher_id/users',
    getWaysToEarnReport: 'report/centre/:center_id/ways-to-earn-points',
    getReferAFriendReport: 'report/centre/:center_id/refer-friend',

    getQRCodeScanByUsers: 'report/centre/:center_id/qr-code/:qr_code_id/users',

    sendVoucherPushNotification: 'report/centre/:center_id/voucher/:voucher_id/notification',
    sendVoucherPushNotificationUser: 'report/centre/:center_id/voucher/:voucher_id/user/:user_id/notification',

    /*** QR CODE  ***/
    getQrCodeList: 'centre/:center_id/qr-codes',
    getQrCodeDetail:'centre/:center_id/qr-code/:id',    
    getQrCodeImg: 'store/:center_id/qr-code/image',
    addQrCode: 'qr-code',
    updateQrCode: 'qr-code/:id', 
    deleteQrCode: 'centre/:center_id/qr-code/:id',
    getQrCodePdf: 'centre/:center_id/qr-code/:id/pdf',
    getQrCodeZip: 'centre/:center_id/qr-code/zip',

    /*** Vouchers ***/
    getVouchersbyGroup: 'centre/:center_id/voucher/names?filter_by[voucher_group_id]=:voucher_group_id',    
    getVouchers: 'centre/:center_id/vouchers',
    getVoucherById: 'centre/:center_id/voucher/:voucher_id',
    addVoucher: 'voucher',
    updateVoucher: 'voucher/:id',
    deleteVoucher: 'centre/:center_id/voucher/:id',

    /*** Push Notifications ***/
    getPushNotifications: 'centre/:center_id/push-notifications',
    getDeviceCount: 'centre/:center_id/push-notifcation/users/count',

    /*** Banners ***/
    getBanners: ':center_id/banners',
    getBannerDetails: 'banner/:id',
    addBanner: 'banner',
    updateBanner: 'banner/:id',
    deleteBanner: 'banner/:id',

    // Activity Log
    getActivityLog: ':center_id/activity-log',
    getUserActivityLog: ':center_id/user-activity-log',
        
    /*** Users ***/    
    getUsers: 'centre/:center_id/users',
    users:'users/getAllUsers',
    updaterole:'users/updateRole',
    userreport:'users/report',

    // Dashboard
    getSignupCount: 'centre/:center_id/dashboard/signup-count',
    getGenderCount: 'centre/:center_id/dashboard/gender-count',
    getSignupCountDuration: 'centre/:center_id/dashboard/signup-count-duration',
    getDeviceCountDuration: 'centre/:center_id/dashboard/device-count-duration',

    getTopVouchersClaimed: 'report/centre/:center_id/vouchers/claimed',
    getTopVouchersRedeemed: 'report/centre/:center_id/vouchers/redeemed',

    getTopQRCodeScan: 'centre/:center_id/dashboard/top-qrcode-scan-count',
    getTopRetailerUser: 'centre/:center_id/dashboard/top-stores-most-vouchers-spend-count',
    getTopVouchersCalimed: 'report/centre/:center_id/vouchers',
    getTotalUserScanned: 'centre/:center_id/dashboard/top-voucher-earners-count',
    getTotalPointEarners: 'centre/:center_id/dashboard/top-point-earners-count',

    getQrCodeScanCount: 'report/centre/:center_id/qr-codes-scan-count',
    getVoucherCount: 'report/centre/:center_id/vouchers-count',
    getAppOnlyOfferCount: 'centre/:center_id/dashboard/apps-only-offer-count',

    /*** Devices ***/
    devices:'devices/getAllDevices',

    // Admin Portal -> Activity Log 
    activityLog: ':center_id/activity-log',

    // Ways to Earn Points 
    addWaysToEarnPoint: 'ways-earn-points',
    getWaysToEarnPoint: 'ways-earn-points/1/app_download',    // App Download Points, app_download, update_profile, refer_friend, scan_qr_code
    getAllWaysToEarnPoint: 'centre/:center_id/ways-earn-points',

    /*** Dashboard ***/
    signupcount:'users/getSignupCount',
    signupcountselectedduration:'users/getSignupCountForSelectedDuration',
    countsummary:'users/getCountSummary',
    latestfiveusers:'users/getLatestFiveUsers',
    downloadscount:'devices/getDownloadsCount',
	gendercount:'users/getAgeGenderCount',

    /*** Push notification ***/
    sendnotification:'push-notification/users/send',
    selectusers:'users/getSelectedUsers',
    selectlocations:'users/getSelectedLocations',
    countpushnotification:'users/countPushNotification',
    pushnotifications:'pushnotifications/getAllPushnotifications',
    deletepushnotification:'pushnotifications/deletePushNotification',
    getUsersData: 'centre/:center_id/user/email-name',
    

    /*** Reset Password ***/    
    forgotpassword:'users/forgotPassword',
    getUserByVerificationToken:'users/getUserByVerificationToken',

    /*** Update User Profile  ***/
    updateprofile: 'users/updateAdminProfile',

     /*** tickets  ***/
    tickets:'tickets/getAllTickets',
    prioritescategorieslist:'tickets/getPrioritiesCategories',
    statuslist:'tickets/getStatus',
    submitTicket:'tickets/submitTicket',
    viewticket:'tickets/viewTicketDetails',
    postReply:'tickets/postReply',
    deleteticket:'tickets/deleteTicket',

    // Stores
    getStores: ':center_id/stores',    
    getStoresByVoucherId: 'store/getStoresByVoucherId',
    getStoresName: ':center_id/store/names',    

    /*** Vouchers ***/
    getvouchergroups: 'vouchers/getVoucherGroups',
    savevoucher:'vouchers/saveVoucher',
    updatevoucher:'vouchers/updateVoucher',
    vouchers:'vouchers/listVoucher',
    getvoucherdetailsbyid:'vouchers/getVoucherDetailsById',
    deletevoucher:'vouchers/deleteVoucher',
    getusersbyvoucher:'vouchers/listVoucherUsers',
    redeemvoucher:'vouchers/redeemVoucherByAdmin',
    sendvoucher:'vouchers/sendVouchers',
    voucherreport:'vouchers/report',
    voucherusers:'vouchers/voucherusers',

    /**** Roles ***/
    getroles:'role',
    getRolePermissions:'role/role_id/permission',
    updaterolepermissions:'role/permission',
    getModulesByRole:'role/role_id/module',    

    /*** Promotions ***/
    savePromotion: 'promotions/savePromotion',
    updatePromotion: 'promotions/updatePromotion',
    promotions: 'promotions/listPromotions',
    getPromotionDetailsById: 'promotions/getPromotionDetailsById',
    deletePromotion: 'promotions/deletePromotion',
    getUsersByClaimPromotions: 'promotions/getUsersByClaimPromotions',
    acceptRejectClaimPromotion: 'promotions/acceptRejectClaimPromotion',
    getAppVouchersByCenter: 'promotions/getAppVouchersByCenter',
    promotionreport:'promotions/report',
    reportUsersByClaimPromotions:'promotions/reportPromClaimedUsers',

    1: "https://www.chevronrenaissanceshoppingcentre.com.au/",
    2: "https://www.adelaidecentralplaza.com.au",
    3: "https://www.macarthurcentral.com.au",
    4: "https://www.portadelaideplaza.com.au",
    5: "https://www.prancentral.com.au",
    6: "https://www.shore-city.co.nz"
}
